/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

.acrylic {
  background-color: rgba(255, 255, 255, 0.5) !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
}

.icon-primary {
  color: var(--primary-color);
}

.card-active {
  background-color: var(--primary-100) !important;

}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--surface-100);  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, var(--surface-300), var(--surface-100));  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, var(--surface-300), var(--surface-100)); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);  /* fallback for old browsers */
  border-radius: 10px;
}

/* scrollbar Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-700);
}

.fixed-paginator {
  position: fixed;
  bottom: 70px;
  right: 5%;
}

.fixed-paginator p-paginator {
  box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.5);
  -webkit-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.5);
  -moz-box-shadow: 0px 4px 10px -3px rgba(0,0,0,0.5);
  border-radius: 10px;
}

@media (max-width:600px) {
  .fixed-paginator {
    position: relative;
    bottom: auto;
    right: auto;
  }
}
